import { format } from 'date-fns';
import { CampaignData, Event } from '../../global/interfaces';
import { checkDateRangeStatus } from '../../utils';
import Icon from '../Icon/Icon';
import SmallDashboardBar from '../small-dashboard-bar/small-dashboard-bar';
import typeBanner from '../../global/images/banner.svg';
import './single-campaign.scss';

type SingleCampaignProps = {
  campaign: CampaignData;
  userIsDemo: boolean;
  handleCleanCampaign?: (e: Event['Button']) => void;
  showImage?: boolean;
  showAdsDetails?: boolean;
};

const SingleCampaign = ({
  campaign,
  userIsDemo,
  handleCleanCampaign,
  showImage = true,
  showAdsDetails = true,
}: SingleCampaignProps) => (
  <div className="single-campaign">
    <SmallDashboardBar
      id={campaign.adCampaignId}
      key={campaign.adCampaignId}
      linkTo={`/org/campaigns/${campaign.adCampaignId}`}
      name={campaign.name}
      imageUrl={showImage ? typeBanner : undefined}
      showImage={showImage}
      description={campaign?.brand?.name}
    >
      <div className="small-dashboard-bar__campaign">
        <p className="small-dashboard-bar__campaign_time">
          <span className={checkDateRangeStatus(campaign.ads?.items?.length, campaign.startDate, campaign.endDate)}>
            {checkDateRangeStatus(campaign.ads?.items?.length, campaign.startDate, campaign.endDate)}
          </span>
          {format(new Date(campaign.startDate), 'MMM dd, yyyy')} - {format(new Date(campaign.endDate), 'MMM dd, yyyy')}
        </p>
        <div className="small-dashboard-bar__campaign_stats">
          {showAdsDetails && (
            <p className={`${!campaign.ads?.items?.length ? 'small-dashboard-bar__campaign_stats--no-ads' : ''}`}>
              {campaign.ads?.items?.length ? `${campaign.ads.items.length} Ads` : 'No Ads'}
            </p>
          )}
          <p>
            200k
            <Icon name="eye" size={16} />
          </p>
          <p>${80}</p>
        </div>
        {campaign.isDemo && userIsDemo ? (
          <button
            name={campaign.adCampaignId}
            className="primary-btn small-dashboard-bar__campaign_clean"
            onClick={handleCleanCampaign}
          >
            X
          </button>
        ) : null}
      </div>
    </SmallDashboardBar>
  </div>
);

export default SingleCampaign;
