import { useState } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationData, UserData } from '../../../global/interfaces';
import { GET_ORG_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { AssetType, UserRole } from '../../../global/consts';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { isRoleAllowed } from '../../../utils';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import OrgBanner from '../../../components/banners/org-banner/org-banner';
import GetStarted from '../../../components/get-started/get-started';
import Statistics from '../../../components/statistics/statistics';
import NewGameModal from '../../../components/modal/new-game-modal/new-game-modal';
import CampaignModal from '../../../components/modal/campaign-modal/campaign-modal';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import NewCollectionModal from '../../../components/modal/new-collection-modal/new-collection-modal';

import placeholder from '../../../global/images/avatar.webp';

import './organization-dashboard-page.scss';

const activityType: { [key: string]: string } = {
  AD: 'Ad',
  OWNABLE_ASSET: 'Ownable asset',
  BRANDED_ASSET: 'Branded asset',
  AD_CAMPAIGN: 'Campaign',
  COLLECTION: 'Collection',
  GAME: 'Game',
  ORG: 'Organization',
};

const OrganizationDashboardPage = () => {
  const [isNewGameModalOpen, setIsNewGameModalOpen] = useState<boolean>(false);
  const [isNewCollectionModalOpen, setIsNewCollectionModalOpen] = useState<boolean>(false);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState<boolean>(false);

  const { data: user, loading: userLoading } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const {
    data: orgData,
    loading: orgLoading,
    refetch,
  } = useReneQuery<{ Organization: OrganizationData } | undefined>(GET_ORG_QUERY);

  const org = orgData?.Organization;

  const showRecently = () => {
    return (
      <div className="organization_dashboard_page__main_recently_items">
        {user?.User?.userActivity?.slice(0, 4).map((activity, i) => (
          <Link key={i} to={activity.activity.path} className="organization_dashboard_page__main_recently_items_item">
            <img src={activity.activity.image || placeholder} alt="entity" />
            <p>{activity.activity.name}</p>
          </Link>
        ))}
      </div>
    );
  };

  const showFeed = () => {
    if (!org?.userActivity?.length) {
      return (
        <div className="organization_dashboard_page__main_feed_no-items">
          {user && isRoleAllowed(user?.User.role, [UserRole.ADVERTISER, UserRole.CREATOR]) && (
            <button onClick={() => setIsNewGameModalOpen(true)}>
              <Icon name="gamepad" size={40} />
              <h2>Game</h2>
              <div>
                <p>
                  Create a home for your Unity game, where you can generate your API credentials, access your asset
                  collections, and manage your settings.
                </p>
              </div>
              <p>
                Create now <Icon name="arrow-forward" />
              </p>
            </button>
          )}
          {user && isRoleAllowed(user?.User.role, [UserRole.ADVERTISER, UserRole.DEVELOPER]) && (
            <button onClick={() => setIsNewCollectionModalOpen(true)}>
              <Icon name="play-card" size={40} />
              <h2>Collection</h2>
              <div>
                <p>
                  Build an Asset Collection to upload your Branded or Ownable Assets and customize their parameters like
                  Resolution and Interactivity.
                </p>
              </div>
              <p>
                Create now
                <Icon name="arrow-forward" />
              </p>
            </button>
          )}
          {user && isRoleAllowed(user?.User.role, [UserRole.CREATOR, UserRole.DEVELOPER]) && (
            <button onClick={() => setIsNewCampaignModalOpen(true)}>
              <Icon name="campaign" size={40} />
              <h2>Campaign</h2>
              <div>
                <p>
                  Craft your first product placement or brand awareness campaign here, analyze your ad performance, and
                  target your ideal game audiences.
                </p>
              </div>
              <p>
                Create now <Icon name="arrow-forward" />
              </p>
            </button>
          )}
        </div>
      );
    }
    return (
      <div className="organization_dashboard_page__main_feed_items">
        {org.userActivity.map((activity, i) => (
          <div key={i}>
            <p>
              {activityType[activity.activity.type]} <Link to={activity.activity.path}>{activity.activity.name}</Link>{' '}
            </p>
            <p>
              updated by <strong>{activity.fullName}</strong>
            </p>
            <p>{activity.activity.timeAgo}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="organization_dashboard_page">
      <main className="organization_dashboard_page__main">
        <div className="organization_dashboard_page__main_content">
          <OrgBanner image={org?.image?.url} title={org?.name}>
            <Statistics
              data={{
                games: orgData?.Organization?.stats.games,
                collections: orgData?.Organization?.stats.collections,
                campaigns: orgData?.Organization?.stats.adCampaigns,
                assets: orgData?.Organization?.stats.mintedAssets,
                players: orgData?.Organization?.stats.players,
              }}
            />
          </OrgBanner>
          {user?.User?.userActivity?.length ? (
            <div className="organization_dashboard_page__main_recently">
              <h2>Recently</h2>
              {userLoading ? <LoadingFallback /> : showRecently()}
            </div>
          ) : null}
          {!orgLoading ? (
            <div className="organization_dashboard_page__main_feed">
              <h2>
                {!org?.userActivity.length
                  ? `Welcome ${user?.User?.data?.firstName} ${user?.User?.data?.lastName}, to start, please create`
                  : 'Feed'}
              </h2>
              {showFeed()}
            </div>
          ) : (
            <LoadingFallback />
          )}
        </div>
        <div className="organization_dashboard_page__main_actions">
          <div className="organization_dashboard_page__main_actions_quick">
            <h2>Quick actions</h2>
            <div>
              {user && isRoleAllowed(user?.User.role, [UserRole.DEVELOPER]) && (
                <button onClick={() => setIsNewGameModalOpen(true)}>
                  <Icon name="gamepad" />
                  <div>
                    <p>Create game</p>
                    <p>Start using cross-border game asset</p>
                  </div>
                </button>
              )}

              {user && isRoleAllowed(user?.User.role, [UserRole.ADVERTISER, UserRole.DEVELOPER]) && (
                <button onClick={() => setIsNewCollectionModalOpen(true)}>
                  <Icon name="play-card" />
                  <div>
                    <p>Create collection</p>
                    <p>Arrange your asset into collections</p>
                  </div>
                </button>
              )}
              {user && isRoleAllowed(user?.User.role, [UserRole.CREATOR, UserRole.ADVERTISER]) && (
                <button onClick={() => setIsNewCampaignModalOpen(true)}>
                  <Icon name="campaign" />
                  <div>
                    <p>Create campaign</p>
                    <p>Start using cross-border ads</p>
                  </div>
                </button>
              )}
            </div>
          </div>
          <div className="organization_dashboard_page__main_actions_start">
            {user && <GetStarted user={user?.User} />}
          </div>
        </div>
      </main>
      <Modal isOpen={isNewGameModalOpen}>
        <NewGameModal setCloseModal={() => setIsNewGameModalOpen(false)} />
      </Modal>
      <Modal isOpen={isNewCollectionModalOpen}>
        <NewCollectionModal type={AssetType.BRANDED} setCloseModal={() => setIsNewCollectionModalOpen(false)} />
      </Modal>
      <Modal isOpen={isNewCampaignModalOpen}>
        <CampaignModal refetch={refetch} setCloseModal={() => setIsNewCampaignModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationDashboardPage;
