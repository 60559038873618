import { useCallback, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useLazyReneQuery, useReneQuery } from '../../../hooks';
import { addQuarters, differenceInDays, endOfQuarter, format, isPast, setYear, startOfYear } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { checkDateRangeStatus, isRoleAllowed } from '../../../utils';
import { CampaignsData, UserData } from '../../../global/interfaces';
import { AD_ENGAGEMENT_QUERY, GET_AD_CAMPAIGN_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { AdEngagementInterval, AdType, UserRole } from '../../../global/consts';
import { CAMPAIGNS, ORG } from '../../../global/routes';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import Select from '../../../components/select/select';
import NewAdModal from '../../../components/modal/new-ad-modal/new-ad-modal';
import CatalogPopup from '../../../components/catalog-popup/catalog-popup';
import ModifyAdModal from '../../../components/modal/modify-ad-modal/modify-ad-modal';
import CampaignModal from '../../../components/modal/campaign-modal/campaign-modal';
import CampaignBanner from '../../../components/banners/campaign-banner/campaign-banner';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import BrandedAssetCatalog from './branded-asset-catalog/branded-asset-catalog';

import './organization-campaign-page.scss';

const selectOptions = {
  [AdEngagementInterval.LAST_WEEK]: 'Last week',
  [AdEngagementInterval.LAST_MONTH]: 'Last month',
  [AdEngagementInterval.LAST_6_MONTHS]: 'Last 6 months',
  [AdEngagementInterval.LAST_YEAR]: 'Last year',
  [AdEngagementInterval.Q1]: 'Q1',
  [AdEngagementInterval.Q2]: 'Q2',
  [AdEngagementInterval.Q3]: 'Q3',
  [AdEngagementInterval.Q4]: 'Q4',
};

const NoAds = ({ title }: { title: string }) => {
  return (
    <div className="organization-campaign-page__main_empty">
      <Icon name="bucket" />
      <p>{title}</p>
    </div>
  );
};

const OrganizationCampaignPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isNewAdModalOpen, setIsNewAdModalOpen] = useState<boolean>(false);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState<boolean>(false);
  const [engagementInterval, setEngagementInterval] = useState(AdEngagementInterval.LAST_WEEK);
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const { data, refetch, loading } = useReneQuery<{ AdCampaigns: CampaignsData } | undefined>(GET_AD_CAMPAIGN_QUERY, {
    variables: { adCampaignId: params.adCampaignId },
  });
  const selectedCampaign = data?.AdCampaigns.items[0];
  const selectedAd =
    params.adId && data ? data.AdCampaigns.items[0].ads.items.find((ad) => ad.adId === params.adId) : undefined;

  const [fetchEngagement, { data: adEngagementData }] = useLazyReneQuery<{ AdEngagement: any[] } | undefined>(
    AD_ENGAGEMENT_QUERY,
  );

  const adEngagement = adEngagementData ? [...adEngagementData.AdEngagement].reverse() : undefined;
  const status =
    selectedCampaign &&
    checkDateRangeStatus(selectedCampaign?.ads?.items?.length, selectedCampaign?.startDate, selectedCampaign?.endDate);

  console.log(status);
  const allowEditCampaign = isRoleAllowed(user?.User.role as UserRole, [UserRole.ADVERTISER]);

  const handleSelectAd = useCallback(
    (adId: string) => {
      navigate(adId);
    },
    [navigate],
  );

  useEffect(() => {
    if (engagementInterval) {
      const date = format(new Date(), 'yyyy-MM-dd');
      const variables: {
        adCampaignId: string | undefined;
        timeType: string;
        amount?: number;
        day?: string;
        week?: string;
        month?: string;
        year?: string;
      } = {
        adCampaignId: params.adCampaignId,
        timeType: 'BEFORE',
      };

      if (engagementInterval === AdEngagementInterval.LAST_WEEK) {
        variables.amount = 7;
        variables.day = date;
      }

      if (engagementInterval === AdEngagementInterval.LAST_MONTH) {
        variables.amount = 31;
        variables.day = date;
      }

      if (engagementInterval === AdEngagementInterval.LAST_6_MONTHS) {
        variables.amount = 6;
        variables.month = date;
      }

      if (engagementInterval === AdEngagementInterval.LAST_YEAR) {
        variables.amount = 2;
        variables.year = date;
      }

      if (
        engagementInterval === AdEngagementInterval.Q1 ||
        engagementInterval === AdEngagementInterval.Q2 ||
        engagementInterval === AdEngagementInterval.Q3 ||
        engagementInterval === AdEngagementInterval.Q4
      ) {
        const getQuartersWithDays = (year: number) => {
          const startOfYearDate = startOfYear(setYear(new Date(), year));
          const quarters: { [key: string]: { date: string; days: number } } = {};

          for (let i = 0; i < 4; i++) {
            const quarterStartDate = addQuarters(startOfYearDate, i);
            const quarterEndDate = endOfQuarter(quarterStartDate);
            const nextQuarterStartDate = addQuarters(startOfYearDate, i + 1);
            const daysInQuarter = differenceInDays(nextQuarterStartDate, quarterStartDate);

            quarters[`Q${i + 1}`] = {
              date: quarterEndDate.toISOString().split('T')[0],
              days: daysInQuarter,
            };
          }

          return quarters;
        };

        const currentYear = new Date().getFullYear();
        const quartersWithDays = getQuartersWithDays(currentYear);
        variables.amount = quartersWithDays[engagementInterval].days;
        variables.day = quartersWithDays[engagementInterval].date;
      }

      fetchEngagement({
        variables,
      });
    }
  }, [engagementInterval, params.adCampaignId, fetchEngagement]);

  const handleAdEngagementInterval = (value: AdEngagementInterval) => {
    setEngagementInterval(value);
  };

  const handleFormatDate = (time: Date) => format(new Date(time), 'dd/MM/yyyy');

  const highestImpressionObject = adEngagement?.reduce(
    (max, current) => (parseInt(current.impression) > parseInt(max.impression) ? current : max),
    adEngagement[0],
  );

  return (
    <div className="organization-campaign-page">
      <div className="organization-campaign-page__back">
        <Link to={`/${ORG}/${CAMPAIGNS}`}>
          <Icon name="chevron-left" size={16} />
          <p className="organization-campaign-page__back_desktop">All campaigns</p>
          <p className="organization-campaign-page__back_mobile">Back</p>
        </Link>
      </div>
      <main className="organization-campaign-page__main">
        <CampaignBanner
          status={status as string}
          campaignType={'Ad Banner'}
          adCampaignId={data?.AdCampaigns?.items[0]?.adCampaignId}
          allowEditCampaign={allowEditCampaign}
          name={data?.AdCampaigns?.items[0].name}
          description={data?.AdCampaigns?.items[0].description}
          campaignImage={data?.AdCampaigns?.items[0]?.campaignImage?.url}
          stats={data?.AdCampaigns.items[0]?.stats}
          startDate={data?.AdCampaigns.items[0].startDate}
          brandName={data?.AdCampaigns?.items[0]?.brand?.name}
          endDate={data?.AdCampaigns.items[0].endDate}
          setIsOpenCampaignModal={() => setIsCampaignModalOpen(true)}
          refetch={refetch}
        />
        {adEngagement?.length ? (
          <section className="organization-campaign-page__main_graph">
            <div className="organization-campaign-page__main_graph_heading">
              <h2>Insight</h2>
              <Select
                value={engagementInterval}
                options={selectOptions}
                changeHandler={handleAdEngagementInterval}
                showSelectedValueFn={(value) => value}
              >
                <Icon name="calendar" />
              </Select>
            </div>
            <div className="organization-campaign-page__main_graph_chart">
              <ResponsiveContainer width="100%" minHeight="300px">
                <LineChart
                  data={adEngagement}
                  margin={{
                    top: 30,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 " stroke="#2D293D" />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    dataKey="intervalDate"
                    tickFormatter={handleFormatDate}
                    tickSize={15}
                  />
                  <YAxis
                    tick={{ fontSize: 12 }}
                    type="number"
                    domain={[0, Number(highestImpressionObject?.impression)]}
                  />
                  <Tooltip />
                  <Line type="monotone" dataKey="impression" stroke="#0ffa79" dot={false} />
                  {/* <Line type="monotone" dataKey="interaction" stroke="#5a41d9" dot={false} /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="organization-campaign-page__main_graph_legend">
              <p>Impressions</p>
              {/* <p>Interactions</p> */}
            </div>
          </section>
        ) : null}
        <section className="organization-campaign-page__main_ads">
          <div>
            <h1>
              Ads <span>{data?.AdCampaigns?.items[0]?.ads?.items?.length}</span>
            </h1>
            {data?.AdCampaigns.items[0].adCampaignId &&
              allowEditCampaign &&
              !isPast(new Date(data.AdCampaigns.items[0].endDate)) && (
                <button className="secondary-btn" onClick={() => setIsNewAdModalOpen(true)}>
                  <Icon name="plus" />
                  Add
                </button>
              )}
          </div>
          {loading ? (
            <LoadingFallback />
          ) : (
            <div>
              {data?.AdCampaigns.items[0]?.ads?.items.length ? (
                data?.AdCampaigns.items[0].ads.items.map((ad) => (
                  <button
                    key={ad.adId}
                    className="organization-campaign-page__main_ads_ad"
                    onClick={() => handleSelectAd(ad.adId)}
                  >
                    <div>
                      {ad?.banner?.url && <img src={ad?.banner?.url} alt="ad" />}
                      {ad?.video?.url && (
                        <video autoPlay muted>
                          <source src={ad?.video?.url} />
                        </video>
                      )}
                    </div>
                    <div>
                      <p>{ad.adType === AdType.BANNER ? 'Banner ad' : 'Video ad'}</p>
                      <p> {ad.name}</p>
                      <div>
                        <p>
                          <span>{ad?.stats?.impressions}</span> impressions
                        </p>
                        <p>
                          <span>{ad?.stats?.interactions}</span> interactions
                        </p>
                      </div>
                    </div>
                  </button>
                ))
              ) : (
                <NoAds title="No ad has been created" />
              )}
            </div>
          )}
        </section>

        {/* <section className="organization-campaign-page__main_branded-assets">
          <div>
            <h1>
              Branded Assets <span>{1}</span>
            </h1>
            {data?.AdCampaigns.items[0].adCampaignId &&
              allowEditCampaign &&
              !isPast(new Date(data.AdCampaigns.items[0].endDate)) && (
                <button className="secondary-btn" onClick={() => setIsCatalogOpen(true)}>
                  <Icon name="plus" />
                  Add
                </button>
              )}
          </div>
          {loading ? (
            <LoadingFallback />
          ) : [].length ? (
            <div className="branded-asset-list">
              {[].map((asset: any) => {
                return (
                  <Link to={''}>
                    <img
                      src="https://files.reneverse.io/branded_asset/images/313d827a-ca08-4dbd-8d37-0e801799745b/cdfc256c-9e66-4e3f-b176-56f0f88e8c83/cdfc256c-9e66-4e3f-b176-56f0f88e8c83.jpeg?version=7ae97a04-ce94-4e13-9627-39b46f10b8bb"
                      alt="branded asset"
                    />
                    <div>
                      <p>Nintendo</p>
                      <p>Hotline</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <NoAds title="No branded assets added" />
          )}
        </section> */}
      </main>
      <Modal isOpen={isNewAdModalOpen}>
        <NewAdModal
          adCampaignId={data?.AdCampaigns?.items[0].adCampaignId as string}
          refetch={refetch}
          setCloseModal={() => setIsNewAdModalOpen(false)}
        />
      </Modal>
      <Modal isOpen={!!selectedAd}>
        <ModifyAdModal
          allowEditCampaign={allowEditCampaign && !isPast(new Date(data?.AdCampaigns.items[0].endDate as string))}
          ad={selectedAd}
          refetch={refetch}
          setCloseModal={() => navigate(`/${ORG}/${CAMPAIGNS}/${params.adCampaignId}`)}
        />
      </Modal>
      <Modal isOpen={isCampaignModalOpen}>
        <CampaignModal
          isEdit
          refetch={refetch}
          previousCampaign={data?.AdCampaigns.items[0]}
          setCloseModal={() => setIsCampaignModalOpen(false)}
        />
      </Modal>
      <CatalogPopup isMenuOpen={isCatalogOpen} setIsOpen={setIsCatalogOpen}>
        <BrandedAssetCatalog setCloseModal={() => setIsCatalogOpen(false)} />
      </CatalogPopup>
    </div>
  );
};

export default OrganizationCampaignPage;

// const CustomDateModal = ({
//   startDate,
//   endDate,
//   setCloseModal,
//   handleApplyCustomDate,
// }: {
//   startDate: Date;
//   endDate: Date;
//   setCloseModal: () => void;
//   handleApplyCustomDate: () => void;
// }) => {
//   const today = new Date();
//   const { errors, isFormInvalid } = useValidation(validations);
//   const [selectedYear, setSelectedYear] = useState(getYear(today));
//   const [selectedMonth, setSelectedMonth] = useState(getMonth(today));
//   const [selectedDay, setSelectedDay] = useState(getDate(today));

//   const generatePastYears = useMemo(() => {
//     const years = [];
//     for (let i = 0; i < 10; i++) {
//       const year = subYears(new Date(), i).getFullYear();
//       years.push(year);
//     }
//     return years;
//   }, []);

//   const generateMonths = useMemo(() => {
//     const months = [];
//     for (let i = 0; i < 12; i++) {
//       const month = format(setMonth(new Date(), i), 'MMMM');
//       months.push(month);
//     }
//     return months;
//   }, []);

//   const generateDays = useMemo(() => {
//     const daysInMonth = getDaysInMonth(new Date(selectedYear, selectedMonth));
//     return Array.from({ length: daysInMonth }, (_, i) => i + 1);
//   }, [selectedYear, selectedMonth]);

//   const handleApply = () => {
//     if (isFormInvalid({ customDate: { startDate, endDate } })) return;
//     handleApplyCustomDate();
//   };

//   return (
//     <div className="custom-date-modal">
//       <div className="custom-date-modal__heading">
//         <h1>Custom interval</h1>
//         <button type="button" onClick={setCloseModal}>
//           <Icon name="close" size={24} />
//         </button>
//       </div>
//       <div className="custom-date-modal__dates">
//         <Select
//           label="Year *"
//           value={selectedYear.toString()}
//           options={generatePastYears}
//           changeHandler={(value) => setSelectedYear(generatePastYears[value])}
//         />
//         <Select
//           label="Month"
//           value={selectedMonth.toString()}
//           options={generateMonths}
//           changeHandler={(value) => setSelectedMonth(value)}
//           showSelectedValueFn={(value) => value}
//         />
//         <Select
//           label="Day"
//           value={selectedDay.toString()}
//           options={generateDays}
//           changeHandler={(value) => setSelectedDay(generateDays[value])}
//         />
//       </div>
//       <div className="custom-date-modal__error">{errors?.customDate}</div>
//       <div className="custom-date-modal__actions">
//         <button type="button" className="secondary-btn" onClick={setCloseModal}>
//           Cancel
//         </button>
//         <button type="button" className="primary-btn" onClick={handleApply} disabled={!startDate || !endDate}>
//           Apply
//         </button>
//       </div>
//     </div>
//   );
// };
