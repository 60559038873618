import { useMemo, useState } from 'react';
import { useReneQuery } from '../../../hooks';
import { GAMES } from '../../../global/routes';
import { Link, useParams } from 'react-router-dom';
import { GameStatus } from '../../../global/consts';
import { GET_USER_QUERY, GET_GAMES_GAMER_QUERY, GET_USER_POINTS } from '../../../global/gql/queries';
import { AssetData, UserData, GamesData, Event } from '../../../global/interfaces';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import AssetModal from '../../../components/modal/asset-modal/asset-modal';
import AssetsList from '../../../components/asset/assets-list/assets-list';
import GamerBanner from '../../../components/banners/gamer-banner/gamer-banner';
import TransferButton from '../../../components/transfer-button/transfer-button';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import AssetTransferModal from '../../../components/modal/asset-transfer-modal/asset-transfer-modal';
import placeholder from '../../../global/images/avatar.webp';

import './gamer-assets-page.scss';

const GamerAssetsPage = () => {
  const params = useParams();
  const [isAssetModalOpen, setIsAssetModalOpen] = useState<boolean>(false);
  const [isAssetTransferModalOpen, setIsAssetTransferModalOpen] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string>('');

  const { data } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const { data: userPoint } = useReneQuery<{ User: UserData } | undefined>(GET_USER_POINTS);

  const hasBalance = useMemo(() => {
    const { balance } = data?.User.balance || {};
    return !!balance && +balance > 0;
  }, [data?.User.balance]);

  const { data: currentUser } = useReneQuery<{ User: UserData } | undefined>(GET_USER_QUERY);
  const { data: selectedGame } = useReneQuery<{ Games: GamesData }>(GET_GAMES_GAMER_QUERY, {
    variables: {
      gameId: params.gameId,
      status: GameStatus.CONNECT_CONFIRMED,
    },
  });

  const handleSelectAsset = (e: Event['Button']) => {
    const target = e.currentTarget as HTMLButtonElement;
    setSelectedAsset(target.id as AssetData['assetId']);
    setIsAssetModalOpen(true);
  };

  return (
    <div className="gamer_assets_page">
      <GamerBanner user={currentUser?.User} points={userPoint?.User.points?.amount} />
      <div className="gamer_assets_page__back">
        <Link to={`/${GAMES}`}>
          <div>
            <Icon name="chevron-left" size={16} />
            <p>All games</p>
          </div>
        </Link>
      </div>
      {selectedGame ? (
        <div className="gamer_assets_page__game">
          <div>
            <img src={selectedGame?.Games.items[0]?.image?.url || placeholder} alt="game poster" />
          </div>
          <div>
            <h2>{selectedGame?.Games.items[0]?.name}</h2>
            <h3>{selectedGame?.Games.items[0]?.description}</h3>
            <div>
              <p>
                {`${selectedGame?.Games.items[0]?.assets?.items.length}
                ${selectedGame?.Games.items[0]?.assets?.items.length > 1 ? 'assets' : 'asset'}`}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <LoadingFallback />
      )}
      {selectedGame && (
        <AssetsList
          handleSelectAsset={handleSelectAsset}
          assetsList={selectedGame?.Games.items[0]?.assets?.items as AssetData[]}
        />
      )}
      <Modal isOpen={isAssetModalOpen}>
        <AssetModal closeModal={() => setIsAssetModalOpen(false)} assetId={selectedAsset}>
          <TransferButton
            setIsAssetModalOpen={setIsAssetModalOpen}
            setIsAssetTransferModalOpen={setIsAssetTransferModalOpen}
            isTransferAllowed={hasBalance}
          />
        </AssetModal>
      </Modal>
      <Modal isOpen={isAssetTransferModalOpen}>
        <AssetTransferModal setCloseModal={setIsAssetTransferModalOpen} assetId={selectedAsset} />
      </Modal>
    </div>
  );
};

export default GamerAssetsPage;
